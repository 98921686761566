<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatApp" dense>
        <v-btn icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs" exact>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              text
              x-small
              v-on="on"
              @click.stop="newDialog = true"
            >
              new consulting service
            </v-btn>
          </template>
          <span>New Service</span>
        </v-tooltip>
        <MeetTypeNew
          :newDialog="newDialog"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card outlined>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="myMeetingList"
            :items-per-page="5"
          >
            <template v-slot:item.meeting_type_name="{ item }">
              {{ item.meeting_type_name | capitalize }}
            </template>

            <template v-slot:item.pricing="{ item }">
              {{ currency(item.pricing) }}
            </template>

            <template v-slot:item.active="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :color="item.active ? 'primary' : 'error'"
                    dark
                    elevation="0"
                    fab
                    x-small
                    v-on="on"
                    @click.stop="$set(activeDialog, item.id, true)"
                  >
                    <v-icon>{{
                      item.active ? "mdi-check" : "mdi-close"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span
                  >Service is {{ item.active ? "active" : "not active" }} for
                  Service</span
                >
              </v-tooltip>
              <MeetTypeActive
                v-if="activeDialog[item.id]"
                :activeDialog="activeDialog[item.id]"
                :myId="item.id"
                @closeActive="closeActive"
                @submitActive="submitActive"
              />
            </template>

            <template v-slot:item.discount_expires="{ item }">
              {{ item.discount_expires | myDating }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                color="success"
                text
                x-small
                @click.stop="$set(viewDialog, item.id, true)"
              >
                view
              </v-btn>
              <MeetTypeView
                v-if="viewDialog[item.id]"
                :myId="item.id"
                :name="item.meeting_type_name"
                :viewDialog="viewDialog[item.id]"
                @closeView="closeView"
              />

              <v-btn
                color="primary"
                text
                x-small
                @click.stop="$set(editDialog, item.id, true)"
              >
                edit
              </v-btn>
              <BackgroundUpdate
                v-if="editDialog[item.id]"
                :editDialog="editDialog[item.id]"
                :myId="item.id"
                :name="item.meeting_type_name"
                @closeEdit="closeEdit"
                @submitEdit="submitEdit"
              />

              <v-btn
                color="error"
                text
                x-small
                @click.stop="$set(removeDialog, item.id, true)"
              >
                remove
              </v-btn>

              <BackgroundRemove
                v-if="removeDialog[item.id]"
                :myId="item.id"
                :removeDialog="removeDialog[item.id]"
                @closeRemove="closeRemove"
                @submitRemove="submitRemove"
              />
            </template>

            <template v-slot:item.details="{ item }">
              <v-btn
                text
                x-small
                color="indigo"
                :to="{
                  name: 'medics.services.add',
                  params: {
                    id: _encode(item.id),
                    name: _encode(item.meeting_type_name),
                  },
                }"
              >
                details
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>

    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Util from "../../mixins/utils";
import Restful from "@/services/RestFul";
import MeetTypeNew from "../../components/accounts/meet/newone.vue";
import MeetTypeActive from "../../components/accounts/meet/active.vue";
import BackgroundUpdate from "../../components/accounts/meet/update.vue";
import MeetTypeView from "../../components/accounts/meet/view.vue";
// import MeetTypeDiscount from "../../components/accounts/meet/discounting.vue";
import BackgroundRemove from "../../components/accounts/meet/remove.vue";
import WinScroll from "@/mixins/windowscroll";

export default {
  components: {
    MeetTypeNew,
    MeetTypeActive,
    BackgroundUpdate,
    MeetTypeView,
    BackgroundRemove,
  },
  mixins: [Util, WinScroll("position")],
  data: () => ({
    crumbs: [
      {
        exact: true,
        link: true,
        text: "Dashboard",
        to: { name: "medics.dash" },
      },
      {
        exact: true,
        link: true,
        text: "Consulting Services",
        to: { name: "medics.services" },
      },
    ],
    newDialog: false,
    viewDialog: {},
    activeDialog: {},
    editDialog: {},
    discountDialog: {},
    removeDialog: {},
    tabs: null,
    myMeetingList: [],
    headers: [
      { text: "meeting type", value: "meeting_type_name" },
      { text: "description", value: "description" },
      { text: "active", value: "active" },
      { text: "actions", value: "actions", align: "center" },
      { text: "details", value: "details", align: "center" },
    ],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
  }),
  computed: {
    flatApp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    refresh() {
      this.list();
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.subscribers.myMeet
        .list()
        .then((response) => {
          console.log(response.data);
          this.myMeetingList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          // self.snack.color = "error";
          // self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeNew(p) {
      console.log(p);
      this.newDialog = p.state;
    },
    submitNew(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.myMeet
        .create(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      this.newDialog = p.state;
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.myMeet
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.myMeet
        .remove(p.id)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeActive(p) {
      this.$set(this.activeDialog, p.id, p.state);
    },
    submitActive(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.myMeet
        .updateActive(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.activeDialog, p.id, p.state);
    },
    closeDisc(p) {
      this.$set(this.discountDialog, p.id, p.state);
    },
    submitDisc(p) {
      this.snack.bar = true;
      // console.log(p);
      let self = this;
      Restful.subscribers.myMeet
        .updateDisc(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.discountDialog, p.id, p.state);
    },
  },
};
</script>
